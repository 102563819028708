import React from 'react';

import Layout from '../../components/Layout';

export default function Ausbildung() {
	return (
		<Layout title="Ausbildung">
			<h2>Ausbildung</h2>
			<p>
				<ul>
					<li>Breiten- und Mitgliederausbildung</li>
					<li>Angebot von Erste-Hilfe-Kursen</li>
					<li>Sofortmaßnahmen am Unfallort</li>
					<li>Krankenpflege in der Familie</li>
					<li>Erste Hilfe am Kind</li>
					<li>Fachbezogene Sanitäts- und Rettungsdienstausbildung</li>
				</ul>
			</p>
			<p>
				Aktuelle Ausbildungstermine finden Sie auf der Seite zu{' '}
				<a href="https://www.brk-bgl.de/kurse/" target="_blank">
					Kursen
				</a>{' '}
				des BRK Kreisverbandes Berchtesgadener Land.
			</p>
		</Layout>
	);
}
